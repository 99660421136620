.table {
    &__row {

        &--unavailable {
            color: $midGrey
        }
    }
}

.table-row {
    &__name {
        color: black !important;
        text-align: center !important;
        font-weight: 700 !important;

        &--unavailable {
            color: $midGrey !important;
        }

        &--done {
            color: $greenColor !important;
        }
    }
}

.month-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;

    &__header {
        background-color: $tealColor;
        height: 50px;
        color: $whiteColor;
    }
}

.month-table td {
    padding: 8px;
    border: 1px solid #ddd;
    background-color: white;
}

.month-table th {
    border: 1px solid #ddd;
    font-weight: 500;
    text-align: left !important;
    padding-left: 10px;
}

.month-column {
    &__clickable {
        cursor: pointer;

        &:hover {
            font-weight: 600;
            color: $blueColor;
        }
    }
}