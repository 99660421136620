.notification-container {
    width: 100%;
    z-index: 100;
    position: sticky;
    top: 0px;
    color: $whiteColor;

    &__message {
        &--success {
            background-color: $greenColor;
        }

        &--info {
            background-color: $blueColor;
        }

        &--warning {
            background-color: $yellowColor;
        }

        &--error {
            background-color: $redColor;
        }
    }
}