.breadcrumbs {
    display: flex;
    padding-left: map-get($sizes, 5);
    border-bottom: 1px solid $greyColor;
    padding-bottom: 10px;

    &__element {
        background-color: $corePurple80;
        height: 50px;
        width: 150px;
        margin-right: 30px;
        padding-left: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        color: $whiteColor;

        &--active {
            background-color: $darkPurple;
            pointer-events: none;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                width: 0;
                height: 0;
            }

            &::before {
                left: 0px;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
                border-left: 25px solid $whiteColor;
            }

            &::after {
                right: -24px;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
                border-left: 25px solid $darkPurple;
            }

        }

        &--unavailable {
            background-color: $corePurple40;
            pointer-events: none;
            cursor: none;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                width: 0;
                height: 0;
            }

            &::before {
                left: 0px;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
                border-left: 25px solid $whiteColor;
            }

            &::after {
                right: -24px;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
                border-left: 25px solid $corePurple40;
            }
        }

        &--normal {

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                width: 0;
                height: 0;
            }

            &::before {
                left: 0px;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
                border-left: 25px solid $whiteColor;
            }

            &::after {
                right: -24px;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
                border-left: 25px solid $corePurple80;
            }
        }

        &:hover {
            background-color: $darkPurple;

            &::after {
                right: -24px;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
                border-left: 25px solid $darkPurple;
            }
        }
    }
}