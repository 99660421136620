.notification-icon {
    color: $whiteColor;
    cursor: pointer;
}

.notification-layout-container {
    width: 350px;

    &__title {
        margin-left: 10px;
        margin-bottom: 15px;
    }

    &__element-container {
        margin-top: 10px;
        border-bottom: solid 1px $greyColor;
        padding-left: 10px;
        display: flex;
        align-items: center;
    }
}

.notification-element {
    &__content {
        width: 80%;
    }

    &__close {
        float: right;
        height: 100%;
        width: 20%;
        align-items: center;
        display: flex;
        margin-right: 5px;
        justify-content: right;

        .notification-close-icon {
            cursor: pointer;
        }
    }
}

.notification-element-content {
    &__description {
        font-size: 14px;
    }

    &__url {
        color: $blueColor;
        cursor: pointer;
        margin-bottom: 10px;

        &:hover {
            text-decoration: underline;

        }
    }
}