.mainApp {
    overflow: hidden;

    &__leftMenu {
        height: calc(100vh - ($subHeaderHeight + $headerHeight));
        background-color: $lightGrey80;
        float: left;
        border-right: 1px solid $midGrey80;

        &--hidden {
            width: $hiddenLeftsideMenuWidth;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
            box-sizing: border-box;
        }

        &--full {
            width: $leftsideMenuWidth;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
            box-sizing: border-box;
        }
    }

    &__container {
        height: calc(100vh - ($subHeaderHeight + $headerHeight));

        float: right;
        position: relative;

        &--full {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
            box-sizing: border-box;
            width: calc(100% - $hiddenLeftsideMenuWidth);
        }

        &--hidden {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
            box-sizing: border-box;
            width: calc(100% - $leftsideMenuWidth);
        }
    }
}

.page {
    height: calc(100vh - $headerHeight - $subHeaderHeight);
    overflow: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}