.secondary-text {
    color: $darkGreyColor;
}

.small-text {
    font-size: small;
}

.no-wrap-text-container {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.gt-button {
    width: 100%;
    height: 40px;
}

.margin {
    &-top {
        &-5 {
            margin-top: 5px !important;
        }

        &-10 {
            margin-top: 10px !important;
        }

        &-20 {
            margin-top: 20px !important;
        }
    }

    &-bottom{
        &-5 {
            margin-bottom: 5px !important;
        }
        &-10 {
            margin-bottom: 10px !important;
        }

        &-20 {
            margin-bottom: 20px !important;
        }
    }

    &-right {
        &-5 {
            margin-right: 5px !important;
        }
        &-10 {
            margin-right: 10px !important;
        }

        &-20 {
            margin-right: 20px !important;
        }
    }

    &-left {
        &-5 {
            margin-left: 5px !important;
        }
        &-10 {
            margin-left: 10px !important;
        }

        &-20 {
            margin-left: 20px !important;
        }
    }
}