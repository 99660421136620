// Colors
$darkPurple: rgb(43, 20, 77);
$darkPurple80: rgb(79, 45, 127);
$darkPurple60: rgb(128, 114, 148);

$corePurple80: rgb(114, 87, 153);
$corePurple60: rgb(149, 129, 178);
$corePurple40: rgb(185, 171, 204);

$brightPurple: rgb(160, 109, 255);
$brightPurple40:rgba(234, 226, 253, 1);

$midGrey: rgb(214 212 208);
$midGrey80: rgb(229, 227, 224);
$midGrey60: rgb(236, 234, 231);
$midGrey40: rgb(243, 241, 239);
$midGrey20: rgb(249, 248, 247);

$lightGrey80: rgb(245, 243, 241);
$lightGrey60: rgb(247, 246, 245);
$lightGrey40: rgb(250, 249, 248);
$lightGrey20: rgb(252, 252, 252);

$tealColor: rgb(0, 164, 179);
$greenColor: rgb(23, 134, 0);
$lightGreenColor: rgb(179, 213, 172);
$yellowColor: rgb(255, 194, 61);
$coralColor: rgb(255, 81, 73);
$beanRedColor: rgba(236, 94, 82, 1);
$blueColor: rgb(68, 132, 215);
$colorPlatinium: rgb(229, 227, 224);
$greyColor: rgb(152, 162, 179);
$darkGreyColor: rgba(71, 84, 103, 1);
$whiteColor: rgb(255, 255, 255);
$greenBlueColor: rgba(0, 164, 179, 1);
$blackColor: rgba(16, 24, 40, 1);
$blackColorOpacity: rgba(16, 24, 40, 0.3);
$lightYellowColor: rgba(255, 239, 202, 1);
$redColor: rgb(217, 21, 12);

//Z-index
$loadingContainerZIndex: 1500;

//Left sidebar
$leftSidebarBorderColor: rgba($darkPurple80, 0.15);
$leftSidebarBgColor: $midGrey40;
