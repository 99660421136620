.leftSideMenu {
    color: $blackColor;
    position: relative;
    height: calc(100vh - ($subHeaderHeight + $headerHeight));

    &__expand-button {
        position: absolute !important;
        right: -10px;
        top: 15px;
        padding: 0px !important;
        background: $whiteColor;
    }

    &__toggleVisibilityIcon {
        position: absolute;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        top: 20px;
        right: -16px;
        background: $whiteColor;
        border: 2px solid $leftSidebarBorderColor;
        border-radius: 50%;
        transition: all 500ms;
        z-index: 100;

        &--rotate {
            transform: rotate(180deg);
        }
    }



    &__element {
        display: flex;
        text-align: center;

        align-items: center;
        height: $menuElementHeight;
        border-bottom: 1px solid $midGrey80;
        text-decoration: none;
        color: $blackColor;

        &--hidden {
            padding-left: map-get($map: $sizes, $key: 4);
        }

        &--full {
            padding-left: map-get($map: $sizes, $key: 4);
        }

        &--active {
            background-color: $midGrey80;
        }

        &:hover {
            background-color: $midGrey80;
            cursor: pointer;
        }

        label {
            cursor: pointer;
        }
    }

    &__footer {
        width: 100%;
        position: absolute;
        bottom: 0px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}