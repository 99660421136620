.fileStatusContainer {
  display: flex;
  width: 100%;

  &--notStarted {
    background-color: $whiteColor;
  }

  &--uploading {
    background-color: $whiteColor;
  }

  &--success {
    background-color: $whiteColor;
  }

  &--error {
    background-color: $whiteColor;
  }

  &__fileName {
    font-size: 1rem;
    margin: 10px;
    display: flex;
    align-items: center;
  }

  &__status {
    margin: 10px;
    margin-left: auto;
  }
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-size: small;
}

.custom-table th {
  background-color: $tealColor;
  color: white;
  text-align: left;
  font-weight: 500;
  padding: 8px;
  border: 1px solid #ddd;
}

.custom-table td {
  padding: 8px;
  border: 1px solid #ddd;

}

.acceptedRow {
  background-color: $lightGreenColor;
}

.dateSection {
  width: 17%;
}

.userSection {
  width: 20%;
  word-break: break-word;
}

.valueSection {
  width: 5%;
}

.recVersionSection {
  width: 5%;
}

.recDateSection {
  width: 13%;
}

.nameSection {
  width: 30%;
  word-break: break-word;
}

.buttonSection {
  width: 10%;
}

.remove-button {
  background-color: $coralColor;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.remove-button:hover {
  background-color: #ff3015;
}