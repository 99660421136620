.reconciliation-section {
    margin-top: map-get($map: $sizes, $key: 10);


    &__title {
        background-color: $darkPurple80;
        color: $whiteColor;
        padding-left: 10px;
        height: 40px;
        font-size: larger;
        display: flex;
        align-items: center;
    }

    &__previous-month {
        margin-top: 10px;
        border: 1px solid $midGrey;
    }

    &__field {
        border-left: 1px solid $darkGreyColor;
        border-bottom: 1px solid $darkGreyColor;
        border-top: 1px solid $darkGreyColor;

        &--end {
            border-right: 1px solid $darkGreyColor;
        }
    }

    &__header-field {
        background-color: $corePurple40;
        height: 30px;
        padding-left: 5px;
        display: flex;
        align-items: center;
    }

    &__value-field {
        height: 25px;
        padding-left: 5px;
        display: flex;
        align-items: center;
        padding-right: 5px;
        justify-content: end;
        font-weight: 500;

        &--valid {
            color: $greenColor;
            font-weight: 600;
        }

        &--invalid {
            color: $redColor;
            font-weight: 600;
        }

        &--text{
            justify-content: start;
        }
    }
}

.reconciliation-buttons {
    margin-top: map-get($map: $sizes, $key: 10);
    margin-bottom: map-get($map: $sizes, $key: 10);

    &__button {
        width: 100%;
        height: 50px;

        &--accept {
            background-color: $greenColor;
        }

        &--decline {
            background-color: $coralColor !important;
            opacity: 0.7;
        }
    }
}