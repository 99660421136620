$titleHeight: 60px;

.pageLayout {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;

    &__header {
        height: $contentHeaderHeight;
        color: $whiteColor
    }

    &__content {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
        height: calc(100% - $contentHeaderHeight - $contentFooterHeight)
    }

    &__footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        height: $contentFooterHeight;
    }

    &__title {
        font-weight: 500;
        height: $titleHeight;
        display: flex;
        padding-left: map-get($map: $sizes, $key: 8);
        align-items: center;
        margin-top: map-get($map: $sizes, $key: 2);
        margin-bottom: map-get($map: $sizes, $key: 2);
    }

    &__breadcrumb {
        display: flex;
        align-items: center;
        height: calc($contentHeaderHeight - $titleHeight);
        background-color: $corePurple60;
        padding-left: map-get($map: $sizes, $key: 5);
        color: $whiteColor;
    }
}