.gt-header {
    background-color: $darkPurple;
    height: $headerHeight;

    &__logo {
        float: left;
    }

    &__positioned-menu {
        float: right;
        height: $headerHeight;
        display: flex;
        justify-content: end;
        align-items: center;
        padding-right: 20px;
        background-color: $darkPurple;
        color: $whiteColor;
    }
}

.gt-sub-header {
    height: $subHeaderHeight;
    display: block;
    align-items: center;

    &__title {
        color: $whiteColor;
        font-size: 30px;
        padding-left: map-get($map: $sizes, $key: 4);
        margin-top: 15px;
        display: inline-block;
    }

    &__actions {
        float: right;
        margin-right: 30px;
        margin-top: 20px;
    }
}

.gt-logo-icon {
    margin-left: map-get($map: $sizes, $key: 17);
    height: 30px;
    transform: scale(5);
    margin-top: map-get($map: $sizes, $key: 3);
}