@import './fonts/index.scss';
@import './styles//variable.scss';
@import './styles/sizes.scss';
@import './styles/theme.scss';
@import './styles/gt-app-root.scss';
@import './styles/gt-header.scss';
@import './styles/gt-layout.scss';
@import './styles/gt-left-side-menu.scss';
@import './styles/gt-page-layout.scss';
@import './styles/gt-spinner.scss';
@import './styles/pages/monthly-page.scss';
@import './styles/gt-drag-drop-file.scss';
@import './styles/gt-file-upload-status-element.scss';
@import './styles/reconciliation.scss';
@import './styles/notification.scss';
@import './styles/gt-breadcrumbs.scss';
@import './styles/common.scss';
@import './styles/notification-layout.scss';
@import './styles/components/gt-progress-bar.scss';
@import './styles/annual-report-process.scss';

body {
  margin: 0;
  padding: 0;
  font-family: "GT-Walsheim-Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}