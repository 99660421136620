@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.loadingContainer {
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  display: flex;

  &--fullScreen {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 10000;
    background-color: $blackColorOpacity;
  }

  &__area {
    width: 200px;
    text-align: -webkit-center;

    &--big {
      width: 500px;
    }

    &--fullScreen {
      border-radius: 10px;
      background-color: $whiteColor;
    }
  }

  &--small {
    height: 40px;
  }

  &__spinner {
    width: 80px;
    height: 80px;
    animation: rotation 1.2s infinite linear;

    &--small {
      width: 40px;
      height: 40px;
    }
  }
}