.gt-progress-bar-container {
    width: 100%;

    .gt-progress-bar {
        width: 100%;
        height: 30px;
        border-radius: 1%;
        display: flex;
        box-sizing: border-box;

        &__done {
            background-color: $darkPurple80;
            width: 70%;
            height: 100%;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            position: relative;
        }

        &__empty {
            background-color: $corePurple40;
            width: 30%;
            height: 100%;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            z-index: 2;
        }
    }

    .gt-progress-bar-description {
        margin-top: 5px;

        &__text {
            float: left;
        }

        &__timer {
            float: right;
        }
    }

    .moon-icon {
        width: 30%;
        height: 100%;
        background: radial-gradient(circle, $darkPurple60 1%, transparent 50%);
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-50%);
        animation: moon-animation 1.5s linear infinite;
    }

    @keyframes moon-animation {
        0% {
            left: 5%;
        }

        100% {
            left: 100%;
        }
    }
}