@font-face {
  font-family: "GT-Walsheim-Pro";
  src: local("GT-Walsheim-Pro-Light"),
    url("./GT-Walsheim-Pro-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "GT-Walsheim-Pro";
  src: local("GT-Walsheim-Pro-Regular"),
    url("./GT-Walsheim-Pro-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "GT-Walsheim-Pro";
  src: local("GT-Walsheim-Pro-Medium"),
    url("./GT-Walsheim-Pro-Medium.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "GT-Walsheim-Pro";
  src: local("GT-Walsheim-Pro-Bold"),
    url("./GT-Walsheim-Pro-Bold.ttf") format("truetype");
  font-weight: 700;
}
